<template>
  <section id="dashboard-ecommerce">
    <div v-if="loading">
      <b-row align-h="center" v-if="loading">
        <b-spinner
          class="mt-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </div>
    <div v-else>
      <b-row class="match-height">
        <b-col lg="3" md="4" cols="12">
          <b-card class="earnings-card">
            <b-row>
              <b-col>
                <b-card-title class="mb-1">Today's Inquiries</b-card-title>

                <b-card-text class="font-small-6 font-weight-bolder mb-0">
                  <span class="font-weight-bolder mb-0" style="font-size: 20px"
                    >12</span
                  >
                </b-card-text>
                <!-- <b-card-text class="font-small-5 mb-3">
          Receipt - {{ data.collectionCount }}
        </b-card-text> -->
                <b-button style="margin-top: 10px" variant="primary">
                  View
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" cols="12">
          <b-card class="earnings-card">
            <b-row>
              <b-col>
                <b-card-title class="mb-1">Today's Admissions</b-card-title>

                <b-card-text class="font-small-6 font-weight-bolder mb-0">
                  <span class="font-weight-bolder mb-0" style="font-size: 20px"
                    >32</span
                  >
                </b-card-text>
                <!-- <b-card-text class="font-small-5 mb-3">
          Receipt - {{ data.collectionCount }}
        </b-card-text> -->
                <b-button style="margin-top: 10px" variant="primary">
                  View
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" cols="12">
          <b-card class="earnings-card">
            <b-row>
              <b-col>
                <b-card-title class="mb-1">Today's Dropouts</b-card-title>

                <b-card-text class="font-small-6 font-weight-bolder mb-0">
                  <span class="font-weight-bolder mb-0" style="font-size: 20px"
                    >4</span
                  >
                </b-card-text>
                <!-- <b-card-text class="font-small-5 mb-3">
          Receipt - {{ data.collectionCount }}
        </b-card-text> -->
                <b-button style="margin-top: 10px" variant="primary">
                  View
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Students</b-card-title>
            </b-card-header>
            <b-card-body>
              <chartjs-component-bar-chart
                :height="400"
                :data="barchartOptions.data"
                :options="barchartOptions.options"
                :plugins="plugins"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <b-col md="12">
          <b-tabs class="pt-1" pills fill>
            <b-tab title="All" active>
              <b-card>
                <b-table responsive :items="dummy" class="mb-0 mt-2">
                  <template #cell(class)="data">
                    <b-avatar size="36" variant="light-primary">
                      <span style="font-size: 16px">{{
                        data.value.charAt(0)
                      }}</span>
                    </b-avatar>
                    <b-badge variant="light-primary" class="ml-1">
                      {{ data.value.substr(1) }}
                    </b-badge>
                  </template>
                  <template #cell(boys)="data">
                    <b-badge variant="light-primary">
                      {{ data.value }}
                    </b-badge>
                  </template>
                  <template #cell(girls)="data">
                    <b-badge variant="light-primary">
                      {{ data.value }}
                    </b-badge>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab
              v-for="dep in departments"
              :key="dep.id"
              :title="dep.name"
              lazy
            >
              <b-card>
                <b-table responsive :items="dummy" class="mb-0 mt-2">
                  <template #cell(class)="data">
                    <b-avatar size="36" variant="light-primary">
                      <span style="font-size: 16px">{{
                        data.value.charAt(0)
                      }}</span>
                    </b-avatar>
                    <b-badge variant="light-primary" class="ml-1">
                      {{ data.value.substr(1) }}
                    </b-badge>
                  </template>
                  <template #cell(boys)="data">
                    <b-badge variant="light-primary">
                      {{ data.value }}
                    </b-badge>
                  </template>
                  <template #cell(girls)="data">
                    <b-badge variant="light-primary">
                      {{ data.value }}
                    </b-badge>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BCardHeader,
  BCardBody,
  BTab,
  BTabs,
  BCardTitle,
  BCardText,
  BTable,
  BBadge,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import ChartjsComponentBarChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";

import ChartjsLineChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import ChartjsLineAreaChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineAreaChart.vue";
import { mapActions, mapGetters } from "vuex";

import { $themeColors } from "@themeConfig";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTab,
    BTabs,
    BTable,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BBadge,
    ChartjsComponentBarChart,
    ChartjsLineChart,
    ChartjsLineAreaChart,
  },
  data() {
    return {
      departments: [],
      //   data: {},
      dash: {},
      monthlyData: {},
      monthlySeries: [],
      weeklyData: {},
      yearlyData: {},
      loading: true,
      dummy: [
        {
          class: "IX - Chemistry",
          boys: 30,
          girls: 50,
        },
        {
          class: "X - Computer",
          boys: 43,
          girls: 27,
        },
        {
          class: "O Levels - Mathematics",
          boys: 34,
          girls: 45,
        },
      ],

      // to add spacing between legends and chart
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      barchartOptions: {
        data: {
          labels: [
            "IX - Computer",
            "IX - Chemistry",
            "X - Computer",
            "X - Chemistry",
            "O Levels - Mathematics",
            "O Levels - Biology",
            "O Levels II - Mathematics",
            "O Levels II - Biology",
          ],
          datasets: [
            {
              label: "Boys",
              data: ["42", "33", "35", "42", "65", "52", "38", "27"],
              backgroundColor: "#836AF9",
              borderColor: "transparent",
            },
            {
              label: "Girls",
              data: ["18", "44", "30", "24", "75", "50", "52", "71"],
              backgroundColor: "#00cfe8",
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 5,
              borderSkipped: "bottom",
              barThickness: 40,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: "top",
            align: "end",
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 12,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 20,
                  min: 0,
                  max: 100,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    };
  },
  created() {
    this.LoadDepartments();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      this.loading = false;
    },
    async LoadData() {
      this.loading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Users/Dashboard?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=2023",
        token: this.$store.state.userData.token,
      };
      this.dash = await this.get(obj);
      // console.log("dash", this.dash);
      this.setData();
    },
    setData() {
      this.barchartOptions.data.labels = [];
      this.barchartOptions.data.datasets[0].data = [];
      this.barchartOptions.data.datasets[1].data = [];
      this.barchartData.data.forEach((el) => {
        this.barchartOptions.data.labels.push(el.class);
        this.barchartOptions.data.datasets[0].push(el.boys);
        this.barchartOptions.data.datasets[1].push(el.girls);
      });
      //   this.weeklyData = {
      //     data: this.dash.collectionGraph,
      //     highestCollection: this.dash.highestCollection,
      //   };
      //   this.yearlyData = {
      //     data: this.dash.duesGraph,
      //     highestDues: this.dash.highestDues,
      //   };
      //   this.monthlyData = this.dash.collectionChart;
      //   this.monthlySeries = [
      //     this.dash.collectionChart.collection,
      //     this.dash.collectionChart.dues,
      //   ];
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
